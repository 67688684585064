<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Product Enquiries</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="accent" class="mr-2" v-on="on">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Product</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: 'module-grogreen-product-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Enquiries</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="enquiries"
        no-data-text="There are currently no enquiries"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            class="mr-2"
            :to="{
              name: 'module-grogreen-productenquiries-individual',
              params: { enquiryId: item.id },
            }"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "groGreen",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Product Enquiries",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email", sortable: false },
        { text: "Phone", value: "phone", sortable: false },
        { text: "Company", value: "company", sortable: false },
        { text: "Date/Time Submitted", value: "date", sortable: false },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    enquiries() {
      let enquiries = this.$store.state.grogreen.enquiries["enquiries"];

      if (this.searchTerm !== "") {
        enquiries = enquiries.filter((p) => {
          const name = p.name.toLowerCase();
          const slug = p.slug.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            slug.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return enquiries;
    },
  },
};
</script>
